exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-230824-ein-nussiger-kuchen-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/230824-ein-nussiger-kuchen/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-230824-ein-nussiger-kuchen-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-230902-snickerskuchen-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/230902-snickerskuchen/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-230902-snickerskuchen-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240222-lauchquiche-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240222-lauchquiche/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240222-lauchquiche-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-bananenbrot-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-bananenbrot/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-bananenbrot-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-rotkohl-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-rotkohl/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-rotkohl-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-semmelknodel-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-semmelknödel/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240223-semmelknodel-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-beste-salatsose-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-beste-salatsose/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-beste-salatsose-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-kohlkartoffelbrei-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-kohlkartoffelbrei/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-kohlkartoffelbrei-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-kottbullar-in-sose-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-köttbullar-in-sose/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240226-kottbullar-in-sose-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240401-schokonussmuffins-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240401-schokonussmuffins/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240401-schokonussmuffins-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240402-kohlnudeln-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240402-kohlnudeln/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240402-kohlnudeln-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240426-basilikumcookies-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240426-basilikumcookies/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240426-basilikumcookies-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240426-schokopaste-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240426-schokopaste/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-240426-schokopaste-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-vorlage-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/_vorlage/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-vorlage-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-italy-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/italy/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-italy-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-mediteranean-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/mediteranean/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-mediteranean-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-mountains-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/mountains/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-mountains-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-onboard-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/onboard/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-projects-onboard-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meineadresse-js": () => import("./../../../src/pages/meineadresse.js" /* webpackChunkName: "component---src-pages-meineadresse-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-230824-ein-nussiger-kuchen-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/230824-ein-nussiger-kuchen/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-230824-ein-nussiger-kuchen-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-230902-snickerskuchen-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/230902-snickerskuchen/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-230902-snickerskuchen-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240222-lauchquiche-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240222-lauchquiche/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240222-lauchquiche-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-bananenbrot-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-bananenbrot/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-bananenbrot-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-rotkohl-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-rotkohl/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-rotkohl-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-semmelknodel-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240223-semmelknödel/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240223-semmelknodel-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-beste-salatsose-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-beste-salatsose/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-beste-salatsose-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-kohlkartoffelbrei-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-kohlkartoffelbrei/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-kohlkartoffelbrei-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-kottbullar-in-sose-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240226-köttbullar-in-sose/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240226-kottbullar-in-sose-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240401-schokonussmuffins-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240401-schokonussmuffins/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240401-schokonussmuffins-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240402-kohlnudeln-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240402-kohlnudeln/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240402-kohlnudeln-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240426-basilikumcookies-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240426-basilikumcookies/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240426-basilikumcookies-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240426-schokopaste-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/240426-schokopaste/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-240426-schokopaste-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-vorlage-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/_vorlage/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-blog-vorlage-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-italy-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/italy/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-italy-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-mediteranean-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/mediteranean/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-mediteranean-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-mountains-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/mountains/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-mountains-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-onboard-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/onboard/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-projects-onboard-index-mdx" */)
}

